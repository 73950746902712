import { Block } from "../common/Block";

export const AboutPageOne = () => {
  return (
    <div>
      <h2>About Page One</h2>

      <Block />
    </div>
  );
};
