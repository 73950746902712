import { Block } from "../common/Block";

export const AboutPageTwo = () => {
  return (
    <div>
      <h2>About Page Two PR4</h2>

      <Block />
    </div>
  );
};
